<template>
    <div>
        <div>
            子传父回调
        </div>
        <div>
            <SonComponent ref="son"  id="aaaa" :callback="theCallback"></SonComponent>
            <button class="layui-btn" @click="doSon()">p</button>
            <a-input type="text" ref="input1"></a-input>
        </div>
    </div>
</template>

<script>
    import SonComponent from './son/son.vue'

    export default {
        components: {SonComponent},
        name: "callback",
        methods: {
            doSon() {
                this.$refs.input1.focus()
                // this.$refs[`input${this.edit_info.type}`].focus()
                // console.log('doSon')
                // console.log(this.$refs.son.$el)
                let son_data = this.$refs.son.son_data
                // let sonFunc = this.$refs.son.sonFunc
                // console.log('son_data', son_data)
                // sonFunc()
            },
            theCallback(e) {
                console.log(e)
            }
        }
    }
</script>

<style scoped>

</style>

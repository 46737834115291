<template>
    <div>
        son
        <div>
            <button class="layui-btn layui-btn-danger" @click="doCallback()">111</button>
        </div>
    </div>
</template>

<script>
    export default {
        props: ['callback'],
        data() {
            return {
                son_data: '12345'
            }
        },
        name: "son",
        methods: {
            sonFunc() {
                console.log('sonFunc')
            },
            doCallback() {
                console.log('doCallback')
                this.callback('----------------------------------------------------')
            },
        }
    }
</script>

<style scoped>

</style>
